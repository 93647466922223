import Vue from 'vue';
import Router from 'vue-router';

// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

// Handle navigation duplication for router push (Globally)

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
  });
};

import store from 'store';

/* Layout */
import Layout from 'views/layout/Layout';
import Ktas from 'router/routers/ktas'
import Tas from 'router/routers/tas'
import Brand from 'router/routers/brand'
import BrandDevices from 'router/routers/brand_devices'
import BrandOperating from 'router/routers/brand_operating'
import Notificaiton from 'router/routers/notification'
import Setting from 'router/routers/setting';
import PriceInquiry from 'router/routers/price_inquiry';
import Operating from 'router/routers/operating';
import routerHelper from 'utils/router';

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('views/login/index'),
    hidden: true,
  },
  { path: '/404', component: () => import('views/404'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Home',
    hidden: true,
    children: [
      {
        path: 'home',
        component: () => import('views/home'),
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
  routes: constantRouterMap,
});

export default router;

let asyncRouterMap = [
  Brand,
  BrandDevices,
  BrandOperating,
  Notificaiton,
  Operating,
  PriceInquiry,
  Tas,
  Setting,
  Ktas,
];

asyncRouterMap = asyncRouterMap.map(router => routerHelper(router));
asyncRouterMap.push({ path: '*', redirect: '/404', hidden: true });
export { asyncRouterMap };

//Update page title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'ZISHI POWER';
  }
  next();
});
