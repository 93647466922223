import Layout from 'views/layout/Layout'

export default {
  path: '/brand/devices',
  redirect: '/brand/devices/list',
  name: 'brandDevices',
  component: Layout,
  title: '智能硬件',
  permission: ['brand'],
  hidden: false,
  icon: 'site',
  children: [
    {
      path: 'list',
      component: () => import('views/brand/devices/Index'),
      title: '智能门禁',
      keepAlive: true,
      name: 'BrandDevicesIndex',
      permission: ['brand'],
      hidden: false,
      children: [
        {
          path: ':brandId/details',
          name: 'BrandDevicesDetails',
          component: () => import('views/brand/devices/Details'),
          title: '智能门禁管理',
          root: true,
          hidden: true,
        },
      ]
    },
  ],
}
